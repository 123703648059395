import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import ModifyVendor from "../../api/ModifyVendor";
import { VendorSchema } from "../../data/validationSchemas/VendorSchema";
import { z } from "zod";
import "../../styles/components/vendor-modal.css";

const VendorModal = ({ vendor, onClose }) => {
  const [vendorData, setVendorData] = useState({
    ...vendor,
    gatheringVendorIDs: Array.isArray(vendor.gatheringVendorIDs)
      ? vendor.gatheringVendorIDs
      : [], // Initialize as empty array if not present
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setVendorData({
      ...vendor,
      gatheringVendorIDs: Array.isArray(vendor.gatheringVendorIDs)
        ? vendor.gatheringVendorIDs
        : [], // Ensure it's an array
    });
  }, [vendor]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setVendorData((prev) => ({ ...prev, [name]: value }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSaveChanges = async () => {
    const result = VendorSchema.safeParse(vendorData);
    if (!result.success) {
      const validationErrors = result.error.errors.reduce((acc, err) => {
        acc[err.path[0]] = err.message;
        return acc;
      }, {});
      setErrors(validationErrors);
      return;
    }

    const success = await ModifyVendor(vendor.id, vendorData);
    if (success) {
      setSuccessMessage("Changes saved successfully.");
    } else {
      setSuccessMessage("Failed to save changes.");
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className="vendor-modal-content">
        <h2>Vendor Details</h2>
        <input
          className="input-field"
          type="text"
          name="vendorName"
          value={vendorData.vendorName}
          onChange={handleInputChange}
          placeholder="Vendor Name"
        />
        {errors.vendorName && (
          <p className="error-message">{errors.vendorName}</p>
        )}

        <input
          className="input-field"
          type="email"
          name="email"
          value={vendorData.email}
          onChange={handleInputChange}
          placeholder="Email"
        />
        {errors.email && <p className="error-message">{errors.email}</p>}

        <input
          className="input-field"
          type="url"
          name="website"
          value={vendorData.website}
          onChange={handleInputChange}
          placeholder="Website"
        />
        {errors.website && <p className="error-message">{errors.website}</p>}

        <textarea
          name="description"
          value={vendorData.description}
          onChange={handleInputChange}
          placeholder="Description"
        />
        {errors.description && (
          <p className="error-message">{errors.description}</p>
        )}

        <div className="associated-gatherings">
          <h3>Associated Gatherings</h3>
          <ul>
            {vendorData.gatheringVendorIDs.length > 0 ? (
              vendorData.gatheringVendorIDs.map((id) => <li key={id}>{id}</li>)
            ) : (
              <p>No associated gatherings</p>
            )}
          </ul>
        </div>

        <button onClick={handleSaveChanges}>Save Changes</button>
        {successMessage && <p className="success-message">{successMessage}</p>}
      </div>
    </Modal>
  );
};

export default VendorModal;
