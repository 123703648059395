import React, { useState } from 'react';
import { auth, firestore } from '../../config/FirebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import Header from '../../components/Header/Header';
import '../../styles/views/auth-view.css';
import '../../styles/components/input-component.css';

const AuthView = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error);
      setMessage('Error signing in. Please check your credentials.');
    }
  };

  const signUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await setDoc(doc(firestore, 'Users', user.uid), { email: user.email, name: '', admin: false });
    } catch (error) {
      console.error('Error creating user:', error);
      alert("Error creating user. Please try again or contact support.");
    }
  };

  const resetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setMessage('Error sending password reset email. Please try again.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn();
  };

  return (
    <div className="auth-container">
      <form className="input-group" onSubmit={handleSubmit}>
        <input
          className="custom-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className="custom-input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Sign In</button>
        <button type="button" onClick={signUp}>Create Account</button>
        
        {/* Forgot Password styled as a link */}
        <button
          type="button"
          onClick={resetPassword}
          style={{
            background: 'none',
            border: 'none',
            color: '#2183A6',
            cursor: 'pointer',
            textDecoration: 'underline',
            padding: '0',
            marginTop: '10px'
          }}
        >
          Forgot Password?
        </button>

        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

export default AuthView;
