import React, { useEffect, useState } from "react";
import { auth, firestore } from "../../config/FirebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import GatheringCreationForm from "../../components/GatheringCreationForm/GatheringCreationForm";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/views/home-view.css";
import FetchUserGatherings from "../../api/FetchUserGatherings";
import getGatheringStatus from "../../logic/getGatheringStatus";
import Modal from "../../components/Modal/Modal";
import FetchUserVendorProfiles from "../../api/FetchUserVendorProfiles";
import updates from "../../content/updates";
import Update from "../../components/UpdateCard/Update";
import { formatDate } from "../../logic/formatDate";
import SupportModal from "../../components/SupportModal/SupportModal";

const HomeView = ({ isAdmin }) => {
  const [showEventCreation, setShowEventCreation] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [shortcode, setShortcode] = useState("");
  const [userGatherings, setUserGatherings] = useState([]);
  const [userVendorProfiles, setUserVendorProfiles] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Fetch user gatherings
  useEffect(() => {
    if (auth.currentUser) {
      const fetchUserGatherings = async () => {
        try {
          const gatherings = await FetchUserGatherings();
          setUserGatherings(gatherings);
        } catch (err) {
          console.error("Error fetching gatherings: ", err);
          setError("Failed to load gatherings. Please try again later.");
        }
      };

      const fetchUserVendorProfiles = async () => {
        try {
          const vendorProfiles = await FetchUserVendorProfiles();
          setUserVendorProfiles(vendorProfiles);
        } catch (err) {
          console.error("Error fetching vendor profiles: ", err);
          setError("Failed to load vendor profiles. Please try again later.");
        }
      };

      fetchUserVendorProfiles();
      fetchUserGatherings();
    } else {
      alert("Permissions issue");
    }
  }, []); // The empty dependency array ensures this runs only once on component mount

  useEffect(() => {
    document.body.style.overflow =
      showEventCreation || showSupportModal ? "hidden" : "auto";
  }, [showEventCreation, showSupportModal]);

  const toggleShowEventCreation = () =>
    setShowEventCreation(!showEventCreation);

  const handleShortcodeSubmit = async () => {
    const gatheringsRef = collection(firestore, "Gatherings");
    const q = query(gatheringsRef, where("Shortcode", "==", shortcode));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const gatheringId = querySnapshot.docs[0].id;
      navigate(`/dashboard/${gatheringId}`);
    } else {
      alert("Gathering with the provided shortcode does not exist.");
    }
  };

  return (
    <div className="home-container">
      <h1>Welcome to itiniri</h1>
      <div className="content-area">
        <div className="left-content">
          <div className="rounded-div welcome-intro">
            <p>
              Welcome to the itiniri platform, the ultimate virtual program
              solution for any event, festival, convention and conference.
            </p>
          </div>
          <div className="rounded-div events-section">
            <div className="section-title">
              <h2>Your Events</h2>
              <button onClick={toggleShowEventCreation}>
                Register a New Event
              </button>
            </div>
            {error ? (
              <p>{error}</p>
            ) : userGatherings[0] ? (
              <table style={{ backgroundColor: "#E5F8FF" }}>
                <thead>
                  <tr style={{ borderBottom: "1px solid lightgrey" }}>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userGatherings.map((gathering) => (
                    <tr
                      key={gathering.id}
                      onClick={() => navigate(`/dashboard/${gathering.id}`)} // Navigate on row click
                      style={{ cursor: "pointer" }} // Make it clear that the row is clickable
                    >
                      <td style={{ color: "#2183A6", fontWeight: "bolder" }}>
                        {gathering.gatheringName}
                      </td>
                      <td>
                        {formatDate(gathering.startDate, false)} to{" "}
                        {formatDate(gathering.endDate, true)}
                      </td>
                      <td>
                        {getGatheringStatus(
                          gathering.startDate,
                          gathering.endDate
                        )}
                        {/* | Visible: {gathering.visible ? "Yes" : "No"} |
            Published: {gathering.published ? "Yes" : "No"} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>
                You don't have any Events currently registered on the platform.
                Click the button above to get started!
              </p>
            )}
          </div>

          <div className="rounded-div support-section">
            <div className="section-title">
              <h2>Support</h2>
            </div>
            <p>
              We offer 24/7 support during our beta release. Please submit any
              support request below and we will get back to you as soon as
              possible.
            </p>
            <button
              className="open-support-window"
              onClick={() => setShowSupportModal(true)}
            >
              Contact Support
            </button>
          </div>
          <SupportModal
            showSupportModal={showSupportModal}
            setShowSupportModal={setShowSupportModal}
          />
        </div>
        <div className="rounded-div updates-section">
          <div className="section-title">
            <h2>Updates</h2>
          </div>
          {updates.map((update, index) => (
            <Update
              key={index}
              title={update.title}
              content={update.content}
              date={update.date}
            />
          ))}
        </div>
      </div>
      {showEventCreation && (
        <Modal
          isOpen={showEventCreation}
          onClose={() => setShowEventCreation(false)}
        >
          <GatheringCreationForm onClose={toggleShowEventCreation} />
        </Modal>
      )}
    </div>
  );
};

export default HomeView;
