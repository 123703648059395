import React, { useEffect, useState } from "react";
import FetchAllGatherings from "../../api/FetchAllGatherings"; // Adjust the path as needed
import FetchAllVendors from "../../api/FetchAllVendors"; // Function to fetch all vendors (to be implemented)
import VendorModal from "../../components/VendorModal/VendorModal"; // Assuming this is where the VendorModal is implemented

const AdminView = () => {
  const [gatherings, setGatherings] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);

  useEffect(() => {
    const fetchGatherings = async () => {
      const adminGatherings = await FetchAllGatherings();
      setGatherings(adminGatherings);
    };

    const fetchVendors = async () => {
      const allVendors = await FetchAllVendors();
      setVendors(allVendors);
    };

    fetchGatherings();
    fetchVendors();
  }, []);

  const openVendorModal = (vendor) => {
    setSelectedVendor(vendor);
  };

  const closeVendorModal = () => {
    setSelectedVendor(null);
  };

  return (
    <div>
      <h1>Admin View</h1>
      {gatherings.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Gathering Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Host Name</th>
              <th>Location</th>
              <th>Host Email</th>
            </tr>
          </thead>
          <tbody>
            {gatherings.map((gathering) => (
              <tr key={gathering.id}>
                <td>
                  <a href={`/dashboard/${gathering.id}`}>
                    {gathering.gatheringName}
                  </a>
                </td>
                <td>{gathering.startDate}</td>
                <td>{gathering.endDate}</td>
                <td>{gathering.hostName}</td>
                <td>{gathering.location}</td>
                <td>{gathering.hostEmail}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No gatherings found.</p>
      )}

      <h2>Vendors</h2>
      {vendors.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Vendor Name</th>
              <th>Email</th>
              <th>Website</th>
              <th># of Gatherings</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {vendors.map((vendor) => (
              <tr key={vendor.id}>
                <td>{vendor.vendorName}</td>
                <td>{vendor.email}</td>
                <td>{vendor.website}</td>
                <td>{vendor.gatheringVendorIDs?.length || 0}</td>
                <td>
                  <button onClick={() => openVendorModal(vendor)}>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No vendors found.</p>
      )}

      {selectedVendor && (
        <VendorModal
          vendor={selectedVendor}
          onClose={closeVendorModal}
        />
      )}
    </div>
  );
};

export default AdminView;
