import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig';
import toCamelCase from '../logic/toCamelCase';
import { Vendor } from '../data/classes/Vendor';

const FetchAllVendors = async (): Promise<Vendor[]> => {
  try {
    const vendorsRef = collection(firestore, "Vendors");
    const querySnapshot = await getDocs(vendorsRef);
    
    const vendors: Vendor[] = querySnapshot.docs.map(docSnap =>
      toCamelCase({ id: docSnap.id, ...docSnap.data() }) as Vendor
    );

    return vendors;
  } catch (error) {
    console.error("Error fetching vendors: ", error);
    return [];
  }
};

export default FetchAllVendors;
