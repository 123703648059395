import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";
import toPascalCase from "../logic/toPascalCase";
import { Vendor } from "../data/classes/Vendor";
import { VendorSchema } from "../data/validationSchemas/VendorSchema";

interface VendorUpdate {
  vendorName?: string;
  email?: string;
  website?: string;
  description?: string;
  gatheringVendorIDs?: string[];
}

const ModifyVendor = async (
  vendorID: string,
  updatedData: Partial<VendorUpdate>
): Promise<boolean> => {
  console.log("Modifying vendor ", JSON.stringify(updatedData));

  // Validate updatedData using the VendorSchema
  const result = VendorSchema.safeParse(updatedData);
  if (!result.success) {
    console.error("Validation failed", result.error);
    return false; // Exit if validation fails
  }

  // Convert valid updatedData to PascalCase for Firestore
  const data = toPascalCase(result.data);
  console.log("PascalCase: " + JSON.stringify(data));

  try {
    const vendorRef = doc(firestore, "Vendors", vendorID);

    // If gatheringVendorIDs are provided, handle them as a special case
    if (updatedData.gatheringVendorIDs) {
      await updateDoc(vendorRef, {
        GatheringVendorIDs: arrayUnion(...updatedData.gatheringVendorIDs),
      });
    }

    // Update other data normally
    await updateDoc(vendorRef, data);
    console.log("Vendor updated with ID: ", vendorID);
    return true;
  } catch (error) {
    console.error("Error updating vendor: ", error);
    return false;
  }
};

export default ModifyVendor;
