import React, { useState } from "react";
import { auth } from "../../config/FirebaseConfig";
import CreateGathering from "../../api/CreateGathering";
import { useNavigate } from "react-router-dom";
import "../../styles/components/gathering-creation-form.css";
import { GatheringSchema } from "../../data/validationSchemas/GatheringSchema";
import { z } from "zod";

const GatheringCreationForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    gatheringName: "",
    description: "",
    location: "",
    shortcode: "",
    startDate: "",
    endDate: "",
    hostID: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const validateInputs = () => {
    try {
      GatheringSchema.parse(formData);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors = error.errors.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleSubmit = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("No authenticated user found. Please sign in.");
      return;
    }

    if (!validateInputs()) {
      alert("Please correct the errors before submitting.");
      return;
    }

    setIsSubmitting(true);
    try {
      const result = await CreateGathering({
        ...formData,
        hostID: currentUser.uid,
      });

      if (result.success) {
        // Navigate to the dashboard if successful
        navigate(`/dashboard/${result.data}`);
      } else {
        // Display error message in the UI if there's an error
        setErrors((prevErrors) => ({
          ...prevErrors,
          apiError: result.error,
        }));
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        apiError: "An unexpected error occurred. Please try again.",
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="gathering-form">
      <h2>Register a New Event</h2>
      {errors.apiError && (
        <p className="error-message">{errors.apiError}</p>
      )}{" "}
      {/* Display API error */}
      <input
        type="text"
        name="gatheringName"
        placeholder="Gathering Name"
        value={formData.gatheringName}
        onChange={handleInputChange}
        required
      />
      {errors.gatheringName && (
        <p className="error-message">{errors.gatheringName}</p>
      )}
      <input
        type="text"
        name="description"
        placeholder="Description"
        value={formData.description}
        onChange={handleInputChange}
        required
      />
      {errors.description && (
        <p className="error-message">{errors.description}</p>
      )}
      <input
        type="text"
        name="location"
        placeholder="Location"
        value={formData.location}
        onChange={handleInputChange}
        required
      />
      {errors.location && <p className="error-message">{errors.location}</p>}
      <input
        type="text"
        name="shortcode"
        placeholder="Shortcode"
        value={formData.shortcode}
        onChange={handleInputChange}
        required
      />
      {errors.shortcode && <p className="error-message">{errors.shortcode}</p>}
      <div className="date-inputs">
        <div className="date-field">
          <label htmlFor="startDate">Start Date</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formData.startDate}
            onChange={handleInputChange}
            required
          />
          {errors.startDate && (
            <p className="error-message">{errors.startDate}</p>
          )}
        </div>

        <div className="date-field">
          <label htmlFor="endDate">End Date</label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={formData.endDate}
            onChange={handleInputChange}
            required
          />
          {errors.endDate && <p className="error-message">{errors.endDate}</p>}
        </div>
      </div>
      <button onClick={handleSubmit} disabled={isSubmitting}>
        {isSubmitting ? "Submitting..." : "Save Event"}
      </button>
      <button onClick={onClose}>Go back</button>
    </div>
  );
};

export default GatheringCreationForm;
