// FetchUserGatherings.js
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../config/FirebaseConfig'; // Adjust the path as needed
import toCamelCase from '../logic/toCamelCase';
import { Vendor } from '../data/classes/Vendor';

const FetchUserVendorProfiles = async (): Promise<Vendor[]> => {
  const userVendorProfiles: Vendor[] = [];

  if (auth.currentUser) {
    const q = query(collection(firestore, "Vendors"), where("VendorUserID", "==", auth.currentUser.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      userVendorProfiles.push(toCamelCase({ id: doc.id, ...doc.data() }) as Vendor);
    });
  }

  return userVendorProfiles;
};


export default FetchUserVendorProfiles;
