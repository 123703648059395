import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig'; 
import toPascalCase from '../logic/toPascalCase';
import { Gathering } from '../data/classes/Gathering';
import { GatheringSchema } from '../data/validationSchemas/GatheringSchema';
import checkAvailability from './CheckAvailability';

const ModifyGathering = async (gatheringID: string, summaryData: Partial<Gathering>): Promise<void | string> => {
  const result = GatheringSchema.partial().safeParse(summaryData);

  if (!result.success) {
    throw new Error(`Validation failed: ${result.error.errors.map(e => e.message).join(", ")}`);
  }

  // Only check availability if `shortcode` and `gatheringName` are provided
  if (summaryData.shortcode || summaryData.gatheringName) {
    const { isShortcodeAvailable, isGatheringNameAvailable } = await checkAvailability(
      summaryData.shortcode, 
      summaryData.gatheringName, 
      summaryData.startDate, 
      summaryData.endDate,
      gatheringID
    );

    if (!isShortcodeAvailable || !isGatheringNameAvailable) {
      const errorMessage = [];
      if (!isShortcodeAvailable) errorMessage.push("Shortcode is already in use.");
      if (!isGatheringNameAvailable) errorMessage.push("Gathering name is already in use.");
      return errorMessage.join(" ");
    }
  }

  const data = toPascalCase(result.data);

  try {
    const gatheringRef = doc(firestore, "Gatherings", gatheringID);
    await updateDoc(gatheringRef, data);
  } catch (error) {
    throw new Error("Error updating gathering");
  }
};

export default ModifyGathering;
