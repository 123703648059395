// src/data/schemas/VendorSchema.ts
import { z } from "zod";

export const VendorSchema = z.object({
  id: z.string(),
  vendorName: z
    .string()
    .min(5, "Vendor Name must be at least 5 characters long.")
    .max(30, "Vendor Name must be less than 50 characters long."),
  vendorUserID: z
    .string(),
  email: z.string().email("Invalid email format"),
  website: z.string().url().optional(),
  description: z.string().max(120, "Description must be less than 120 characters long.").optional(),
  gatheringVendorIDs: z.array(z.string()).optional(), // Validate as an optional array of strings
});

export type VendorInput = z.infer<typeof VendorSchema>;
