import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageConversions from "../logic/imageConversion";

const uploadImage = async (file, gatheringID, directory) => {
  if (!file) return null;

  const filename = `${directory}Image.jpg`; // Constant filename with .jpg extension
  const storage = getStorage();
  const storageRef = ref(storage, `gatherings/${gatheringID}/${directory}/${filename}`);

  try {
    let fileToUpload = file;

    // Convert image to JPEG if it's not already a JPEG
    if (!file.name.endsWith(".jpg") && !file.name.endsWith(".jpeg")) {
      const imageDataUrl = await imageConversions.imageToDataURL(file); // Convert image to data URL
      fileToUpload = imageConversions.dataURLtoFile(imageDataUrl, filename); // Convert data URL back to File object
    }

    const snapshot = await uploadBytes(storageRef, fileToUpload);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    console.error("Failed to upload image", error);
    return null;
  }
};

export default uploadImage;
