import React from "react";
import "../../styles/components/image-uploads.css";

const LogoUpload = ({ logoImage, handleLogoChange }) => {
  return (
    <div className="input-container">
      <h3>Upload a new logo image:</h3>
      
      <label className="upload-button-label" htmlFor="logo-upload">
        Upload Image
      </label>
      <input
        id="logo-upload"
        type="file"
        onChange={handleLogoChange}
        accept="image/*"
        className="upload-button"
      />

      {logoImage && (
        <img className="logo-image" src={logoImage} alt="Gathering Logo" />
      )}
    </div>
  );
};

export default LogoUpload;
